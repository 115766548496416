.full-height {
    height: 100vh; 
    display: flex;
    flex-direction: column;
}

.use-height {
    height: 100%;
}

.map-column {
    display: flex;
    flex-direction: column;
}

.full-height-column {
    display: flex;
    flex-direction:  column;
}

.pane {
    overflow-y: auto;
    height: 100%;
    max-height: 50vh;
}

.leaflet-container {
    height: 100%;
}
